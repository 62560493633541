@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 24px;

  p {
    max-width: 620px;
    width: 100%;
    margin: 0 auto;
  }
}

.sign {
  margin: 0 auto 24px;
  width: 120px;
  height: 120px;
  background-color: $primary;
  color: $white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include text(36px, 700, 36px);
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.main {
  @include text(14px, 400, 14px);
  padding: 8px 14px;
  max-height: fit-content;
}
